import axios from 'src/utils/axiosMain';

export const GET_ALL_POSTS = '@post/get-all-posts';
export const CREATE_POST = '@post/create-post';
export const CREATE_POST_FAILURE = '@post/create-post-failure';
export const UPLOAD_POST_IMAGE = '@post/upload-post-image';
export const GET_POST = '@post/get-post';
export const UPDATE_POST = '@post/update-post';

export function getPosts() {
  return async dispatch => {
    try {
      const response = await axios.get('/api/posts?limit=100');
      dispatch({ type: GET_ALL_POSTS, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function createPost(values) {
  return async dispatch => {
    try {
      const { featuredImg, ...postData } = values;
      const response = await axios.post('/api/posts', postData);
      if (values.featuredImg) {
        values.postId = response.data._id;
        await dispatch(uploadFeaturedImg(values));
        return;
      }
      dispatch({
        type: CREATE_POST,
        payload: response.data
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function uploadFeaturedImg(values) {
  return async dispatch => {
    let fd = new FormData();
    fd.append('featuredImg', values.featuredImg);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios.post(
        `/api/posts/${values.postId}/featuredimg`,
        fd,
        config
      );
      dispatch({
        type: GET_POST,
        payload: response.data
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function getPost(values) {
  return async dispatch => {
    try {
      const response = await axios.get(`/api/posts/${values.postId}`);
      dispatch({
        type: GET_POST,
        payload: response.data
      });
    } catch (error) {
      throw error;
      //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  };
}

export function updatePost(values) {
  // console.log(values);
  return async dispatch => {
    try {
      const response = await axios.patch(`/api/posts/${values.postId}`, {
        ...values
      });
      if (values.featuredImg) {
        await dispatch(uploadFeaturedImg(values));
      }
      dispatch({
        type: UPDATE_POST,
        payload: response.data
      });
    } catch (error) {
      //console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_FAILURE });
      throw error;
    }
  };
}
