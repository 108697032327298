/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/privacidad',
    component: lazy(() => import('src/views/pages/PrivacyView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/terminos',
    component: lazy(() => import('src/views/pages/TermsView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/iniciar-sesión',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/cliente/iniciar-sesión',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/contraseña-olvidada',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/cliente/contraseña-olvidada',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/restablecer-la-contraseña/:token',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/cliente/restablecer-la-contraseña/:token',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/crear-contraseña/:token',
    component: lazy(() => import('src/views/auth/CreatePasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/cliente/crear-contraseña/:token',
    component: lazy(() => import('src/views/auth/CreatePasswordView'))
  },
  {
    exact: true,
    //guard: GuestGuard,
    path: '/verificar-correo-electrónico/:token',
    component: lazy(() => import('src/views/auth/VerifyEmailView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/registro',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/proposals/:id',
    component: lazy(() => import('src/views/management/ProposalDetailsView'))
  },
  {
    path: '/app/dashboard/bienvenida',
    exact: true,
    guard: AuthGuard,
    component: lazy(() => import('src/views/pages/UserOnboardingView'))
  },
  {
    path: '/app/dashboard/plan-de-precios',
    exact: true,
    guard: AuthGuard,
    component: lazy(() => import('src/views/user/UserSubscriptionView'))
  },
  {
    path: '/app/dashboard/incorporación-de-stripe-connect',
    exact: true,
    guard: AuthGuard,
    component: lazy(() => import('src/views/user/UserStripeConnectView'))
  },
  {
    path: '/app/dashboard/terminar',
    exact: true,
    guard: AuthGuard,
    component: lazy(() => import('src/views/user/UserFinishStepsView'))
  },
  {
    exact: true,
    path: '/blog/:postId',
    component: lazy(() => import('src/views/management/PostDetailsView')),
    layout: MainLayout
  },
  {
    exact: true,
    path: ['/blog', '/blog/'],
    component: lazy(() => import('src/views/pages/BlogView')),
    layout: MainLayout
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },

      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        exact: true,
        path: '/app/management/clients',
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/clients/create',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/app/management/clients/import',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/CustomerImportView'))
      },
      {
        exact: true,
        path: '/app/management/clients/create/:fromDashborad',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/app/management/clients/:clientId',
        component: lazy(() =>
          import('src/views/management/CustomerDetailsView')
        )
      },

      {
        exact: true,
        path: '/app/management/clients/:clientId/edit',
        component: lazy(() => import('src/views/management/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/my-products-services',
        component: lazy(() =>
          import('src/views/management/MyProductsServicesView')
        )
      },
      {
        exact: true,
        path: '/app/management/providers',
        component: lazy(() => import('src/views/management/ProviderListView'))
      },
      {
        exact: true,
        path: '/app/management/providers/create',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/ProviderCreateView'))
      },
      {
        exact: true,
        path: '/app/management/providers/:providerId',
        component: lazy(() =>
          import('src/views/management/ProviderDetailsView')
        )
      },

      {
        exact: true,
        path: '/app/management/providers/:providerId/edit',
        component: lazy(() => import('src/views/management/ProviderEditView'))
      },

      {
        exact: true,
        path: '/app/management/products/create',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/products/create/:fromDashborad',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/products/:productId/edit',
        component: lazy(() => import('src/views/management/ProductEditView'))
      },
      {
        exact: true,
        path: '/app/management/services/create',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/ServiceCreateView'))
      },
      {
        exact: true,
        path: '/app/management/services/:serviceId/edit',
        component: lazy(() => import('src/views/management/ServiceEditView'))
      },
      {
        exact: true,
        path: '/app/management/orders/create',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/OrderCreateView'))
      },
      {
        exact: true,
        path: '/app/management/orders/create/:fromDashborad',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/OrderCreateView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId/edit',
        component: lazy(() => import('src/views/management/OrderEditView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/management/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/management/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/proposals',
        component: lazy(() => import('src/views/management/ProposalListView'))
      },
      {
        exact: true,
        path: '/app/management/proposals/create',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/ProposalCreateView'))
      },
      {
        exact: true,
        path: '/app/management/proposals/:proposalId/edit',
        component: lazy(() => import('src/views/management/ProposalEditView'))
      },

      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/management/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/clients" />
      },
      {
        exact: true,
        path: '/app/management/categories',
        component: lazy(() => import('src/views/management/CategoryListView'))
      },
      {
        exact: true,
        path: '/app/management/posts/create',
        component: lazy(() => import('src/views/management/PostCreateView'))
      },
      {
        exact: true,
        path: '/app/management/posts/:postId/edit',
        component: lazy(() => import('src/views/management/PostEditView'))
      },
      {
        exact: true,
        path: '/app/management/posts',
        component: lazy(() => import('src/views/management/PostListView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
