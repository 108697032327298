import { GET_REPORT } from 'src/actions/reportActions';

export default (state = {}, action) => {
    switch (action.type) {
        case GET_REPORT:
            return {...state, ...action.payload};

        default:
            return state;
    }
}