import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function AuthGuard({ children }) {
  const redirectPath = window.location.pathname;
  const urlQueryString = window.location.search;
  const urlHashString = window.location.hash;
  const urlParams = new URLSearchParams(window.location.search);
  const userType = urlParams.get('user_type') ? urlParams.get('user_type') : '';

  const account = useSelector(state => state.account);

  if (!account.user) {
    switch (userType) {
      case 'customer':
        return (
          <Redirect
            to={
              '/cliente/iniciar-sesión?redirect_url=' +
              redirectPath +
              urlQueryString +
              urlHashString
            }
          />
        );

      default:
        return (
          <Redirect
            to={
              '/iniciar-sesión?redirect_url=' +
              redirectPath +
              urlQueryString +
              urlHashString
            }
          />
        );
    }
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
