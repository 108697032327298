import axios from 'src/utils/axiosMain';

export const GET_ALL_CATEGORIES = '@category/get-all-categories';
export const ADD_CATEGORY = '@category/add-category';


export function getCategories(showOwnerDetails = false) {
  return async dispatch => {
    try {
        const response = await axios.get(showOwnerDetails ? '/api/categories?limit=100&ownerDetails=true' : '/api/categories?limit=100');
        dispatch({ type: GET_ALL_CATEGORIES, payload: response.data });

    } catch (error) {
        throw error;
        //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  }
}

export function addCategory(values) {
  return async (dispatch) => {  
    try {
      const response = await axios.post('/api/categories', { ...values });
      dispatch({
        type: ADD_CATEGORY,
        payload: response.data
      });
    } catch (error) {
      //console.log(error);
      throw error;
    }
  };
}
