import {
  // Briefcase as BriefcaseIcon,
  // Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  // BarChart as BarChartIcon,
  // Lock as LockIcon,
  // UserPlus as UserPlusIcon,
  // Shield as ShieldIcon,
  // AlertCircle as AlertCircleIcon,
  // Trello as TrelloIcon,
  // User as UserIcon,
  // Layout as LayoutIcon,
  // Edit as EditIcon,
  // DollarSign as DollarSignIcon,
  // Mail as MailIcon,
  // MessageCircle as MessageCircleIcon,
  //ShoppingBag as ShoppingBagIcon,
  File as FileIcon,
  Package as PackageIcon,
  PieChart as PieChartIcon,
  // Share2 as ShareIcon,
  Users as UsersIcon
} from 'react-feather';

export default [
  {
    subheader: 'Inicio',
    items: [
      {
        title: 'Bienvenido',
        icon: PieChartIcon,
        href: '/app/dashboard'
      }
      // {
      //   title: 'Dashboard Alternative',
      //   icon: BarChartIcon,
      //   href: '/app/reports/dashboard-alternative'
      // }
    ]
  },
  {
    subheader: 'Administración',
    items: [
      {
        title: 'Clientes',
        icon: UsersIcon,
        href: '/app/management/clients',
        id: 'clients-nav',
        items: [
          {
            title: 'Nuevo Cliente',
            href: '/app/management/clients/create'
          },
          {
            title: 'Todos los Clientes',
            href: '/app/management/clients'
          },
          {
            title: 'Importación de clientes',
            href: '/app/management/clients/import'
          }
        ]
      },
      {
        title: 'Productos',
        icon: PackageIcon,
        href: '/app/management/my-products-services',
        id: 'myproducts-nav',
        items: [
          {
            title: 'Nuevo Producto',
            href: '/app/management/products/create'
          },
          {
            title: 'Mis Productos',
            href: '/app/management/my-products-services'
          }
        ]
      },
      // {
      //   title: 'Proveedores',
      //   icon: ShoppingBagIcon,
      //   href: '/app/management/providers',
      //   id: 'providers-nav',
      //   items: [
      //     {
      //       title: 'Todos los Proveedores',
      //       href: '/app/management/providers'
      //     },
      //     {
      //       title: 'Nuevo Proveedor',
      //       href: '/app/management/providers/create'
      //     }
      //   ]
      // },
      {
        title: 'Cotización',
        icon: FileIcon,
        href: '/app/management/proposals',
        id: 'proposals-nav',
        items: [
          {
            title: 'Nueva Cotización',
            href: '/app/management/proposals/create'
          },
          {
            title: 'Cotizaciones',
            href: '/app/management/proposals'
          }
        ]
      },
      {
        title: 'Órdenes',
        icon: ShoppingCartIcon,
        href: '/app/management/orders',
        id: 'orders-nav',
        items: [
          {
            title: 'Nueva Órden',
            href: '/app/management/orders/create'
          },
          {
            title: 'Órdenes de Compra',
            href: '/app/management/orders'
          }
        ]
      },
      // {
      //   title: 'Invoices',
      //   icon: ReceiptIcon,
      //   href: '/app/management/invoices',
      //   items: [
      //     {
      //       title: 'List Invoices',
      //       href: '/app/management/invoices'
      //     },
      //     {
      //       title: 'View Invoice',
      //       href: '/app/management/invoices/1'
      //     }
      //   ]
      // }
    ]
  }
  // {
  //   subheader: 'Applications',
  //   items: [
  //     {
  //       title: 'Projects Platform',
  //       href: '/app/projects',
  //       icon: BriefcaseIcon,
  //       items: [
  //         {
  //           title: 'Overview',
  //           href: '/app/projects/overview'
  //         },
  //         {
  //           title: 'Browse Projects',
  //           href: '/app/projects/browse'
  //         },
  //         {
  //           title: 'Create Project',
  //           href: '/app/projects/create'
  //         },
  //         {
  //           title: 'View Project',
  //           href: '/app/projects/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social Platform',
  //       href: '/app/social',
  //       icon: ShareIcon,
  //       items: [
  //         {
  //           title: 'Profile',
  //           href: '/app/social/profile'
  //         },
  //         {
  //           title: 'Feed',
  //           href: '/app/social/feed'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Kanban',
  //       href: '/app/kanban',
  //       icon: TrelloIcon
  //     },
  //     {
  //       title: 'Mail',
  //       href: '/app/mail',
  //       icon: MailIcon,
  //       info: () => (
  //         <Chip
  //           color="secondary"
  //           size="small"
  //           label="Updated"
  //         />
  //       )
  //     },
  //     {
  //       title: 'Chat',
  //       href: '/app/chat',
  //       icon: MessageCircleIcon,
  //       info: () => (
  //         <Chip
  //           color="secondary"
  //           size="small"
  //           label="Updated"
  //         />
  //       )
  //     },
  //     {
  //       title: 'Calendar',
  //       href: '/app/calendar',
  //       icon: CalendarIcon
  //     },
  //   ]
  // },
  // {
  //   subheader: 'Auth',
  //   items: [
  //     {
  //       title: 'Login',
  //       href: '/login-unprotected',
  //       icon: LockIcon
  //     },
  //     {
  //       title: 'Register',
  //       href: '/register-unprotected',
  //       icon: UserPlusIcon
  //     },
  //     {
  //       title: 'Login: Guest Protected',
  //       href: '/login',
  //       icon: ShieldIcon
  //     },
  //     {
  //       title: 'Register: Guest Protected',
  //       href: '/register',
  //       icon: ShieldIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Pages',
  //   href: '/app/pages',
  //   items: [
  //     {
  //       title: 'Account',
  //       href: '/app/account',
  //       icon: UserIcon
  //     },
  //     {
  //       title: 'Error',
  //       href: '/404',
  //       icon: AlertCircleIcon
  //     },
  //     {
  //       title: 'Pricing',
  //       href: '/pricing',
  //       icon: DollarSignIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Extra',
  //   items: [
  //     {
  //       title: 'Charts',
  //       href: '/app/extra/charts',
  //       icon: BarChartIcon,
  //       items: [
  //         {
  //           title: 'Apex Charts',
  //           href: '/app/extra/charts/apex'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Forms',
  //       href: '/app/extra/forms',
  //       icon: EditIcon,
  //       items: [
  //         {
  //           title: 'Formik',
  //           href: '/app/extra/forms/formik'
  //         },
  //         {
  //           title: 'Redux Forms',
  //           href: '/app/extra/forms/redux'
  //         },
  //       ]
  //     },
  //     {
  //       title: 'Editors',
  //       href: '/app/extra/editors',
  //       icon: LayoutIcon,
  //       items: [
  //         {
  //           title: 'DraftJS Editor',
  //           href: '/app/extra/editors/draft-js'
  //         },
  //         {
  //           title: 'Quill Editor',
  //           href: '/app/extra/editors/quill'
  //         }
  //       ]
  //     }
  //   ]
  // }
];
