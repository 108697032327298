import axios from 'src/utils/axiosMain';
//import axios from 'axios';
// import authService from 'src/services/authService';

export const CREATE_PRODUCT = '@product/create-product';
export const CREATE_MY_PRODUCT = '@product/create-my-product';
export const CREATE_PRODUCT_FAILURE = '@product/create-product-failure';
export const GET_ALL_PRODUCTS = '@product/get-all-products';
export const UPDATE_PRODUCT = '@product/update-product';
export const UPDATE_MY_PRODUCT = '@product/update-my-product';
export const GET_PRODUCT = '@product/get-product';
export const GET_MY_PRODUCT = '@product/get-my-product';
export const UPLOAD_PRODUCT_IMAGE = '@product/upload-product-image';
export const DELETE_PRODUCTS_LIST = '@product/delete-products-list';

export function createProduct(values) {
  return async dispatch => {
    try {
      const response = await axios.post(
        values.providerId ? '/api/createProduct' : '/api/create-my-product',
        { ...values }
      );
      if (values.productImg) {
        values.productId = response.data._id;
        await dispatch(uploadProductImg(values));
        return;
      }
      dispatch({
        type: values.providerId ? CREATE_PRODUCT : CREATE_MY_PRODUCT,
        payload: response.data
      });
    } catch (error) {
      console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_FAILURE });
      throw error;
    }
  };
}

export function uploadProductImg(values) {
  return async dispatch => {
    let fd = new FormData();
    fd.append('productImg', values.productImg);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios.post(
        values.providerId
          ? `/api/products/${values.productId}/productimg?providerId=${values.providerId}`
          : `/api/my-products/${values.productId}/productimg`,
        fd,
        config
      );
      dispatch({
        type: values.providerId ? GET_PRODUCT : GET_MY_PRODUCT,
        payload: response.data
      });
    } catch (e) {
      //dispatch({ type: UPLOAD_PRODUCT_IMAGE, payload: e.response.data });
    }
  };
}

export function updateProduct(values) {
  //console.log(values);
  return async dispatch => {
    try {
      const response = await axios.patch( values.providerId ? `/api/products/${values.productId}` : `/api/my-products/${values.productId}`, {
        ...values
      });
      if (values.productImg) {
        await dispatch(uploadProductImg(values));
      }
      dispatch({
        type: values.providerId ? UPDATE_PRODUCT : UPDATE_MY_PRODUCT,
        payload: response.data
      });
    } catch (error) {
      //console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_FAILURE });
      throw error;
    }
  };
}

export function getProducts() {
  return async dispatch => {
    try {
        const response = await axios.get('/api/my-products?sortBy=createdAt:desc&limit=100');
        dispatch({ type: GET_ALL_PRODUCTS, payload: response.data });

    } catch (error) {
        throw error;
        //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  }
}

export function getProduct(values) {
  return async dispatch => {
    try {
      const response = await axios.get(
        values.providerId
          ? `/api/products/${values.productId}?providerId=${values.providerId}`
          : `/api/my-products/${values.productId}`
      );
      dispatch({ type: values.providerId ? GET_PRODUCT : GET_MY_PRODUCT, payload: response.data });
    } catch (error) {
      throw error;
      //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  };
}

export function deleteProductsList(productsIds) { 
  return async dispatch => {
    try {
        const response = await axios.delete('/api/my-products/delete-list', { data: {productsIds} });
        dispatch({ type: DELETE_PRODUCTS_LIST, payload: response.data });
    } catch (error) {
      console.log(error);
        dispatch({ type: DELETE_PRODUCTS_LIST, payload: error.response.data });
    }
  }
}

