import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

function GuestGuard({ children }) {
  const match = useRouteMatch('/iniciar-sesión');

  const urlParams = new URLSearchParams(
    window.location.search + window.location.hash
  );
  const redirectUrl = urlParams.get('redirect_url')
    ? urlParams.get('redirect_url')
    : '';
  const account = useSelector(state => state.account);

  // if (
  //   account.user &&
  //   account.user.role === 'admin' &&
  //   !account.user.hasOwnProperty('stripeSubscriptionId') &&
  //   account.user.isSubscriptionTrialEnd
  // ) {
  //   return <Redirect to="/app/dashboard/plan-de-precios" />;
  // }

  if (
    !match?.isExact &&
    account.user &&
    account.user.role === 'admin' &&
    (!account.user.hasCategory ||
      !account.user.hasCompanyLogo ||
      !account.user.hasAvatar ||
      !account.user.hasOwnProperty('knowYourBusiness'))
  ) {
    return <Redirect to="/app/dashboard/bienvenida" />;
  }

  if (account.user && redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  if (account.user) {
    return <Redirect to="/app" />;
  }

  console.log('children GuestGuard', children);

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
