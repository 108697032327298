// https://jsbin.com/capiyoyusa/1/edit?js,console
import _ from 'lodash';
import {
    CREATE_CUSTOMER,
    GET_ALL_CUSTOMERS,
    UPDATE_CUSTOMER,
    GET_CUSTOMER,
    GET_CUSTOMER_IMPORTS,
    DELETE_CUSTOMERS_LIST
} from 'src/actions/customerActions';

const INITIAL_STATE = {
    allCustomers: {},
    customersFetched: false,
    customersImports: {},
    customersImportsFetched: false,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_CUSTOMERS:
            // action.payload.forEach(customer => {
            //     customer.orders = _.mapKeys(customer.orders, '_id');
            // });

            return {...state, allCustomers: _.mapKeys(action.payload, '_id'), customersFetched: true};
        
        case CREATE_CUSTOMER:
            if (action.payload.error) return {...state, error: action.payload.error};
            else {
                let allCustomers = { ...state.allCustomers };
                allCustomers = { ...allCustomers, [action.payload._id]: action.payload };
                return { ...state, allCustomers };
            }

        case UPDATE_CUSTOMER:
            if (action.payload.error) return {...state, error: action.payload.error};
            else {
                let allCustomers = { ...state.allCustomers };
                allCustomers = { ...allCustomers, [action.payload._id]: action.payload };
                return { ...state, allCustomers };
            }

        case GET_CUSTOMER:
            let allCustomers = { ...state.allCustomers };
            allCustomers = { ...allCustomers, [action.payload._id]: action.payload };
            return { ...state, allCustomers };

        case GET_CUSTOMER_IMPORTS:
            return {...state, customersImports: _.mapKeys(action.payload, '_id'), customersImportsFetched: true};

        case DELETE_CUSTOMERS_LIST:
            let allCustomersDelete = _.omit(state.allCustomers, action.payload)
            return {...state, allCustomers: {...allCustomersDelete}};
        
        // case DELETE_TASK:
        //     const taskId = action.payload._id;
        //     return _.omit(state, [`allTasks.${taskId}`]);


  
        // case CLEAR_TASK_ERROR:
        //     return {...state, error: null};
        
        // case UPLOAD_TASK_IMAGE:
        //     return state;

        // case CLEAR_ALL_TASKS:
        //     return {};

        default:
            return state;
    }
}