import axios from 'src/utils/axiosMain';
//import axios from 'axios';
// import authService from 'src/services/authService';

export const GET_REPORT = '@report/get-report';


export function getReport(from, to) { 
  return async dispatch => {
    try {
        const response = await axios.get(`/api/report?from=${from}&to=${to}`);
        dispatch({ type: GET_REPORT, payload: response.data });
    } catch (error) {
        throw error;
        //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  }
}
