import axios from 'src/utils/axiosMain';
//import axios from 'axios';
// import authService from 'src/services/authService';

export const CREATE_CUSTOMER_NOTE = '@customer/create-customer-note';
export const CREATE_CUSTOMER_NOTE_FAILURE = '@customer/create-customer-note-failure';
export const GET_CUSTOMER_NOTES = '@customer/get-customer-notes';
export const UPDATE_CUSTOMER_NOTE = '@customer/update-customer-note';
export const DELETE_CUSTOMER_NOTE = '@customer/delete-customer-note';


export function createCustomerNote(values) {
  return async (dispatch) => {  
    try {
      const response = await axios.post('/api/customer-notes', { ...values });
      dispatch({
        type: CREATE_CUSTOMER_NOTE,
        payload: response.data
      });
    } catch (error) {
      //console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_NOTE_FAILURE });
      throw error;
    }
  };
}

export function updateCustomerNote(values, custId) {
  return async dispatch => {
    try {
        const response = await axios.patch(`/api/customer-notes/${custId}`, { ...values });
        dispatch({ type: UPDATE_CUSTOMER_NOTE, payload: response.data });
    } catch (error) {
        //dispatch({ type: UPDATE_CUSTOMER_NOTE, payload: e.response.data });
        throw error;
    }
  }
}

export function deleteCustomerNote(custId) {
  return async dispatch => {
    try {
        const response = await axios.delete(`/api/customer-notes/${custId}`);
        dispatch({ type: DELETE_CUSTOMER_NOTE, payload: response.data });
    } catch (error) {
        //dispatch({ type: UPDATE_CUSTOMER_NOTE, payload: e.response.data });
        throw error;
    }
  }
}

export function getCustomerNotes(custId) {
  return async dispatch => {
    try {
        const response = await axios.get(`/api/customer-notes/${custId}`);
        dispatch({ type: GET_CUSTOMER_NOTES, payload: response.data });   
    } catch (e) {
        dispatch({ type: GET_CUSTOMER_NOTES, payload: e.response.data });
    }
  }
}


