import axios from 'src/utils/axiosMain';
//import axios from 'axios';
// import authService from 'src/services/authService';

export const CREATE_PROPOSAL = '@proposal/create-proposal';
export const CREATE_PROPOSAL_FAILURE = '@proposal/create-proposal-failure';
export const GET_ALL_PROPOSALS = '@proposal/get-all-proposals';
export const UPDATE_PROPOSAL = '@proposal/update-proposal';
export const GET_PROPOSAL = '@proposal/get-proposal';
export const DELETE_PROPOSAL = '@proposal/delete-proposal';
export const DELETE_MANY_PROPOSAL = '@proposal/delete-many-proposal';

export function createProposal(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/proposals', { ...values });
      dispatch({
        type: CREATE_PROPOSAL,
        payload: response.data
      });
    } catch (error) {
      //console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_FAILURE });
      throw error;
    }
  };
}

export function getAllProposals(userRole) {
  return async dispatch => {
    try {
      let apiUrl = '/api/proposals?sortBy=createdAt:desc&limit=100';
      if (userRole === 'customer')
        apiUrl = '/api/customers/my-proposals?sortBy=createdAt:desc&limit=100';
      const response = await axios.get(apiUrl);
      dispatch({ type: GET_ALL_PROPOSALS, payload: response.data });
    } catch (e) {
      dispatch({ type: GET_ALL_PROPOSALS, payload: e.response.data });
    }
  };
}

export function getProposal(values, userRole) {
  return async dispatch => {
    try {
      let apiUrl = `/api/proposals/${values.proposalId}`;
      if (userRole === 'customer')
        apiUrl = `/api/customers/my-proposal/${values.proposalId}`;
      const response = await axios.get(apiUrl);
      dispatch({ type: GET_PROPOSAL, payload: response.data });
    } catch (error) {
      throw error;
      //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  };
}

export function updateProposal(values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/proposals/${values.proposalId}`,
        {
          ...values
        }
      );
      dispatch({
        type: UPDATE_PROPOSAL,
        payload: response.data
      });
    } catch (error) {
      //console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_FAILURE });
      throw error;
    }
  };
}

export function deleteProposal(proposalId, userRole) {
  return async dispatch => {
    try {
      let apiUrl = `/api/proposals/${proposalId}`;
      if (userRole === 'customer')
        apiUrl = `/api/customers/my-proposal/${proposalId}`;
      const response = await axios.delete(apiUrl);
      dispatch({ type: DELETE_PROPOSAL, payload: response.data });
    } catch (error) {
      throw error;
      //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  };
}

export function deleteManyProposal(proposalIds, userRole) {
  return async dispatch => {
    try {
      let apiUrl = `/api/proposals/delete`;
      // if (userRole === 'customer') apiUrl = `/api/customers/my-proposal/`;
      const response = await axios.delete(apiUrl, {
        data: {
          proposalIds
        }
      });
      dispatch({ type: DELETE_MANY_PROPOSAL, payload: response.data });
    } catch (error) {
      throw error;
      //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  };
}
