import axios from 'src/utils/axiosMain';
//import axios from 'axios';
// import authService from 'src/services/authService';

export const CREATE_PROVIDER = '@provider/create-provider';
export const CREATE_PROVIDER_FAILURE = '@provider/create-provider-failure';
export const GET_ALL_PROVIDERS = '@provider/get-all-providers';
export const UPDATE_PROVIDER = '@provider/update-provider';
export const GET_PROVIDER = '@provider/get-provider';


export function createProvider(values) {
  return async (dispatch) => {  
    try {
      const response = await axios.post('/api/providers', { ...values });
      dispatch({
        type: CREATE_PROVIDER,
        payload: response.data
      });
    } catch (error) {
      //console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_FAILURE });
      throw error;
    }
  };
}

export function updateProvider(values, providerId) {
  return async dispatch => {
    try {
        const response = await axios.patch(`/api/providers/${providerId}`, { ...values });
        dispatch({ type: UPDATE_PROVIDER, payload: response.data });
    } catch (error) {
        //dispatch({ type: UPDATE_CUSTOMER, payload: e.response.data });
        throw error;
    }
  }
}

export function getProvider(providerId) { 
  return async dispatch => {
    try {
        const response = await axios.get(`/api/providers/${providerId}`);
        dispatch({ type: GET_PROVIDER, payload: response.data });
    } catch (error) {
        throw error;
        //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  }
}

export function getAllProviders() {
  return async dispatch => {
    try {
        const response = await axios.get('/api/providers?sortBy=createdAt:desc');
        dispatch({ type: GET_ALL_PROVIDERS, payload: response.data });   
    } catch (e) {
        dispatch({ type: GET_ALL_PROVIDERS, payload: e.response.data });
    }
  }
}
