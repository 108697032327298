import React from 'react';
import { Typography, Container } from '@material-ui/core';

const Footer = () => {
  return (
    <footer
      style={{
        padding: '20px 0',
        textAlign: 'center',
        backgroundColor: '#fff',
        borderTop: '1px solid #d5d5d5'
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="body2" color="textSecondary">
          &copy; {new Date().getFullYear()} Circulo Cuadro. All rights reserved.
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
