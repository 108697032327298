import axios from 'src/utils/axiosMain';
//import axios from 'axios';
// import authService from 'src/services/authService';

export const CREATE_SERVICE = '@service/create-service';
export const CREATE_MY_SERVICE = '@service/create-my-service';
export const CREATE_SERVICE_FAILURE = '@service/create-service-failure';
export const GET_ALL_SERVICES = '@service/get-all-services';
export const UPDATE_SERVICE = '@service/update-service';
export const UPDATE_MY_SERVICE = '@service/update-my-service';
export const GET_SERVICE = '@service/get-service';
export const GET_MY_SERVICE = '@service/get-my-service';

export function createService(values) {
  return async dispatch => {
    try {
      const response = await axios.post(
        values.providerId ? '/api/createService' : '/api/create-my-service',
        { ...values }
      );
      dispatch({
        type: values.providerId ? CREATE_SERVICE : CREATE_MY_SERVICE,
        payload: response.data
      });
    } catch (error) {
      console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_FAILURE });
      throw error;
    }
  };
}

export function updateService(values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        values.providerId
          ? `/api/services/${values.serviceId}`
          : `/api/my-services/${values.serviceId}`,
        {
          ...values
        }
      );
      dispatch({
        type: values.providerId ? UPDATE_SERVICE : UPDATE_MY_SERVICE,
        payload: response.data
      });
    } catch (error) {
      //console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_FAILURE });
      throw error;
    }
  };
}

// export function getServices() {
//   return async dispatch => {
//     try {
//         const response = await axios.get('/api/services?sortBy=createdAt:desc&limit=100');
//         dispatch({ type: GET_ALL_SERVICES, payload: response.data });

//     } catch (error) {
//         throw error;
//         //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
//     }
//   }
// }

export function getService(values) {
  return async dispatch => {
    try {
      const response = await axios.get(
        values.providerId
          ? `/api/services/${values.serviceId}?providerId=${values.providerId}`
          : `/api/my-services/${values.serviceId}`
      );
      dispatch({
        type: values.providerId ? GET_SERVICE : GET_MY_SERVICE,
        payload: response.data
      });
    } catch (error) {
      throw error;
      //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  };
}
