import {
  ShoppingCart as ShoppingCartIcon,
  PieChart as PieChartIcon
} from 'react-feather';

export default [
  {
    subheader: 'Inicio',
    items: [
      {
        title: 'Bienvenido',
        icon: PieChartIcon,
        href: '/app/dashboard'
      }
    ]
  },
  {
    subheader: 'Administración',
    items: [
      {
        title: 'Órdenes',
        icon: ShoppingCartIcon,
        href: '/app/management/orders',
        id: 'orders-nav',
        items: [
          {
            title: 'Órdenes de Compra',
            href: '/app/management/orders'
          }
        ]
      }
    ]
  }
];
