// https://jsbin.com/capiyoyusa/1/edit?js,console
import _ from 'lodash';
import {
    CREATE_ORDER,
    GET_ALL_ORDERS,
    UPDATE_ORDER,
    GET_ORDER
} from 'src/actions/orderActions';

const INITIAL_STATE = {
    allOrders: {},
    ordersFetched: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_ORDERS:
            return {...state, allOrders: _.mapKeys(action.payload, '_id'), ordersFetched: true};
        
        case CREATE_ORDER:
            if (action.payload.error) return {...state, error: action.payload.error};
            else {
                let allOrders = { ...state.allOrders };
                allOrders = { ...allOrders, [action.payload._id]: action.payload };
                return { ...state, allOrders };
            }

        case UPDATE_ORDER:
            if (action.payload.error) return {...state, error: action.payload.error};
            else {
                let allOrders = { ...state.allOrders };
                allOrders = { ...allOrders, [action.payload._id]: action.payload };
                return { ...state, allOrders };
            }

        case GET_ORDER:
            let allOrders = { ...state.allOrders };
            allOrders = { ...allOrders, [action.payload._id]: action.payload };
            return { ...state, allOrders };

        default:
            return state;
    }
}