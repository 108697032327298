/* eslint-disable no-param-reassign */
import produce from 'immer';
import _ from 'lodash';
import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  GET_PROFILE,
  PROFILE_UPDATE_FAILURE,
  UPLOAD_USER_AVATAR
} from 'src/actions/accountActions';

import {
  CREATE_MY_PRODUCT,
  GET_MY_PRODUCT,
  UPDATE_MY_PRODUCT
} from 'src/actions/productActions';

import {
  CREATE_MY_SERVICE,
  GET_MY_SERVICE,
  UPDATE_MY_SERVICE
} from 'src/actions/serviceActions';

const initialState = {
  user: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS: {
      const { user } = action.payload;

      user.products = _.mapKeys(user.products, '_id');
      user.services = _.mapKeys(user.services, '_id');

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case REGISTER_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGIN_REQUEST: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;

      if (user.role === 'admin') {
        user.products = _.mapKeys(user.products, '_id');
        user.services = _.mapKeys(user.services, '_id');
      }

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, draft => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      if (user.role === 'admin') {
        user.products = _.mapKeys(user.products, '_id');
        user.services = _.mapKeys(user.services, '_id');
      }

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;

      if (user.role === 'admin') {
        user.products = _.mapKeys(user.products, '_id');
        user.services = _.mapKeys(user.services, '_id');
      }

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case GET_PROFILE: {
      const { user } = action.payload;

      if (user.role === 'admin') {
        user.products = _.mapKeys(user.products, '_id');
        user.services = _.mapKeys(user.services, '_id');
      }

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case UPLOAD_USER_AVATAR: {
      const { user } = action.payload;

      user.products = _.mapKeys(user.products, '_id');
      user.services = _.mapKeys(user.services, '_id');

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case PROFILE_UPDATE_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case CREATE_MY_PRODUCT:
    case GET_MY_PRODUCT:
    case UPDATE_MY_PRODUCT:
      if (action.payload.error)
        return { ...state, error: action.payload.error };
      else {
        return produce(state, draft => {
          draft.user.products[action.payload._id] = action.payload;
        });
      }

    case CREATE_MY_SERVICE:
    case GET_MY_SERVICE:
    case UPDATE_MY_SERVICE:
      if (action.payload.error)
        return { ...state, error: action.payload.error };
      else {
        return produce(state, draft => {
          draft.user.services[action.payload._id] = action.payload;
        });
      }

    default: {
      return state;
    }
  }
};

export default accountReducer;
