/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
// import DocsLayout from 'src/layouts/DocsLayout';
// import MainLayout from 'src/layouts/MainLayout';
// import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
// import SubscriptionGuard from 'src/components/SubscriptionGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/precios',
    component: lazy(() => import('src/views/pages/PricingView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/privacidad',
    component: lazy(() => import('src/views/pages/PrivacyView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/terminos',
    component: lazy(() => import('src/views/pages/TermsView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/iniciar-sesión',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/cliente/iniciar-sesión',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/contraseña-olvidada',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/cliente/contraseña-olvidada',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/restablecer-la-contraseña/:token',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/cliente/restablecer-la-contraseña/:token',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/crear-contraseña/:token',
    component: lazy(() => import('src/views/auth/CreatePasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/cliente/crear-contraseña/:token',
    component: lazy(() => import('src/views/auth/CreatePasswordView'))
  },
  {
    exact: true,
    //guard: GuestGuard,
    path: '/verificar-correo-electrónico/:token',
    component: lazy(() => import('src/views/auth/VerifyEmailView'))
  },
  // {
  //   exact: true,
  //   path: '/login-unprotected',
  //   component: lazy(() => import('src/views/auth/LoginView'))
  // },
  {
    exact: true,
    guard: GuestGuard,
    path: '/registro',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    //guard: GuestGuard,
    path: '/proposals/:id',
    component: lazy(() => import('src/views/management/ProposalDetailsView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/pay/:id',
    component: lazy(() => import('src/views/management/OrderPayView'))
  },
  // {
  //   exact: true,
  //   path: '/register-unprotected',
  //   component: lazy(() => import('src/views/auth/RegisterView'))
  // },
  {
    path: '/app/dashboard/bienvenida',
    exact: true,
    guard: AuthGuard,
    component: lazy(() => import('src/views/pages/UserOnboardingView'))
  },
  // {
  //   path: '/app/dashboard/agrega-tu-logotipo',
  //   exact: true,
  //   guard: AuthGuard,
  //   component: lazy(() => import('src/views/user/UserPhotoUploadView'))
  // },
  // {
  //   path: '/app/dashboard/funciones-preferidas',
  //   exact: true,
  //   guard: AuthGuard,
  //   component: lazy(() => import('src/views/user/UserFunctionView'))
  // },
  {
    path: '/app/dashboard/plan-de-precios',
    exact: true,
    guard: AuthGuard,
    component: lazy(() => import('src/views/user/UserSubscriptionView'))
  },
  {
    path: '/app/dashboard/incorporación-de-stripe-connect',
    exact: true,
    guard: AuthGuard,
    component: lazy(() => import('src/views/user/UserStripeConnectView'))
  },
  {
    path: '/app/dashboard/terminar',
    exact: true,
    guard: AuthGuard,
    component: lazy(() => import('src/views/user/UserFinishStepsView'))
  },
  {
    exact: true,
    path: '/blog/:postId',
    component: lazy(() => import('src/views/management/PostDetailsView')),
    layout: MainLayout
  },
  {
    exact: true,
    path: ['/blog', '/blog/'],
    component: lazy(() => import('src/views/pages/BlogView')),
    layout: MainLayout
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      // {
      //   exact: true,
      //   path: '/app/reports/dashboard-alternative',
      //   component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      // },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        exact: true,
        path: '/app/management/clients',
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/clients/create',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/app/management/clients/import',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/CustomerImportView'))
      },
      {
        exact: true,
        path: '/app/management/clients/create/:fromDashborad',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/app/management/clients/:clientId',
        component: lazy(() =>
          import('src/views/management/CustomerDetailsView')
        )
      },

      {
        exact: true,
        path: '/app/management/clients/:clientId/edit',
        component: lazy(() => import('src/views/management/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/my-products-services',
        component: lazy(() =>
          import('src/views/management/MyProductsServicesView')
        )
      },
      {
        exact: true,
        path: '/app/management/providers',
        component: lazy(() => import('src/views/management/ProviderListView'))
      },
      {
        exact: true,
        path: '/app/management/providers/create',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/ProviderCreateView'))
      },
      {
        exact: true,
        path: '/app/management/providers/:providerId',
        component: lazy(() =>
          import('src/views/management/ProviderDetailsView')
        )
      },

      {
        exact: true,
        path: '/app/management/providers/:providerId/edit',
        component: lazy(() => import('src/views/management/ProviderEditView'))
      },
      // {
      //   exact: true,
      //   path: '/app/management/products',
      //   component: lazy(() => import('src/views/management/ProductListView'))
      // },
      {
        exact: true,
        path: '/app/management/products/create',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/products/create/:fromDashborad',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/products/:productId/edit',
        component: lazy(() => import('src/views/management/ProductEditView'))
      },
      {
        exact: true,
        path: '/app/management/services/create',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/ServiceCreateView'))
      },
      {
        exact: true,
        path: '/app/management/services/:serviceId/edit',
        component: lazy(() => import('src/views/management/ServiceEditView'))
      },
      {
        exact: true,
        path: '/app/management/orders/create',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/OrderCreateView'))
      },
      {
        exact: true,
        path: '/app/management/orders/create/:fromDashborad',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/OrderCreateView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId/edit',
        component: lazy(() => import('src/views/management/OrderEditView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/management/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/management/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/proposals',
        component: lazy(() => import('src/views/management/ProposalListView'))
      },
      {
        exact: true,
        path: '/app/management/proposals/create',
        //guard: SubscriptionGuard,
        guard: AuthGuard,
        component: lazy(() => import('src/views/management/ProposalCreateView'))
      },
      {
        exact: true,
        path: '/app/management/proposals/:proposalId/edit',
        component: lazy(() => import('src/views/management/ProposalEditView'))
      },
      // {
      //   exact: true,
      //   path: '/app/management/invoices',
      //   component: lazy(() => import('src/views/management/InvoiceListView'))
      // },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/management/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/clients" />
      },
      // {
      //   exact: true,
      //   path: '/app/calendar',
      //   component: lazy(() => import('src/views/calendar/CalendarView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/kanban',
      //   component: lazy(() => import('src/views/kanban/KanbanView'))
      // },
      // {
      //   exact: true,
      //   path: [
      //     '/app/chat/new',
      //     '/app/chat/:threadKey'
      //   ],
      //   component: lazy(() => import('src/views/chat/ChatView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/chat',
      //   component: () => <Redirect to="/app/chat/new" />
      // },
      // {
      //   exact: true,
      //   path: [
      //     '/app/mail/label/:customLabel/:mailId?',
      //     '/app/mail/:systemLabel/:mailId?'
      //   ],
      //   component: lazy(() => import('src/views/mail/MailView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/mail',
      //   component: () => <Redirect to="/app/mail/all" />
      // },
      // {
      //   exact: true,
      //   path: '/app/projects/overview',
      //   component: lazy(() => import('src/views/projects/OverviewView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/projects/browse',
      //   component: lazy(() => import('src/views/projects/ProjectBrowseView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/projects/create',
      //   component: lazy(() => import('src/views/projects/ProjectCreateView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/projects/:id',
      //   component: lazy(() => import('src/views/projects/ProjectDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/projects',
      //   component: () => <Redirect to="/app/projects/browse" />
      // },
      // {
      //   exact: true,
      //   path: '/app/social/feed',
      //   component: lazy(() => import('src/views/social/FeedView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/social/profile',
      //   component: lazy(() => import('src/views/social/ProfileView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/social',
      //   component: () => <Redirect to="/app/social/profile" />
      // },
      // {
      //   exact: true,
      //   path: '/app/extra/charts/apex',
      //   component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/extra/forms/formik',
      //   component: lazy(() => import('src/views/extra/forms/FormikView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/extra/forms/redux',
      //   component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/extra/editors/draft-js',
      //   component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/extra/editors/quill',
      //   component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      // },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
  // {
  //   path: '/docs',
  //   layout: DocsLayout,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/docs',
  //       component: () => <Redirect to="/docs/welcome" />
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/welcome',
  //       component: lazy(() => import('src/views/docs/WelcomeView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/getting-started',
  //       component: lazy(() => import('src/views/docs/GettingStartedView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/environment-variables',
  //       component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/deployment',
  //       component: lazy(() => import('src/views/docs/DeploymentView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/api-calls',
  //       component: lazy(() => import('src/views/docs/ApiCallsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/analytics',
  //       component: lazy(() => import('src/views/docs/AnalyticsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/authentication',
  //       component: lazy(() => import('src/views/docs/AuthenticationView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/routing',
  //       component: lazy(() => import('src/views/docs/RoutingView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/settings',
  //       component: lazy(() => import('src/views/docs/SettingsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/state-management',
  //       component: lazy(() => import('src/views/docs/StateManagementView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/theming',
  //       component: lazy(() => import('src/views/docs/ThemingView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/support',
  //       component: lazy(() => import('src/views/docs/SupportView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/changelog',
  //       component: lazy(() => import('src/views/docs/ChangelogView'))
  //     },
  //     {
  //       component: () => <Redirect to="/404" />
  //     }
  //   ]
  // },
  // {
  //   path: '*',
  //   layout: MainLayout,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/home',
  //       component: HomeView
  //     },
  //     {
  //       exact: true,
  //       path: '/pricing',
  //       component: lazy(() => import('src/views/pages/PricingView'))
  //     },
  //     {
  //       component: () => <Redirect to="/404" />
  //     }
  //   ]
  // }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
