// https://jsbin.com/capiyoyusa/1/edit?js,console
import _ from 'lodash';
import {
  CREATE_PROPOSAL,
  GET_ALL_PROPOSALS,
  UPDATE_PROPOSAL,
  GET_PROPOSAL,
  DELETE_PROPOSAL
} from 'src/actions/proposalActions';

const INITIAL_STATE = {
  allProposals: {},
  proposalsFetched: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PROPOSALS:
      return {
        ...state,
        allProposals: _.mapKeys(action.payload, '_id'),
        proposalsFetched: true
      };

    case CREATE_PROPOSAL:
      if (action.payload.error)
        return { ...state, error: action.payload.error };
      else {
        let allProposals = { ...state.allProposals };
        allProposals = {
          ...allProposals,
          [action.payload._id]: action.payload
        };
        return { ...state, allProposals };
      }

    case UPDATE_PROPOSAL:
      if (action.payload.error)
        return { ...state, error: action.payload.error };
      else {
        let allProposals = { ...state.allProposals };
        allProposals = {
          ...allProposals,
          [action.payload._id]: action.payload
        };
        return { ...state, allProposals };
      }

    case GET_PROPOSAL:
      let allProposals = { ...state.allProposals };
      allProposals = { ...allProposals, [action.payload._id]: action.payload };
      return { ...state, allProposals };

    case DELETE_PROPOSAL:
      return _.omit(state, [`allProposals.${action.payload._id}`]);

    default:
      return state;
  }
};
