import { combineReducers } from 'redux';
//import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import customerReducer from './customerReducer';
import customerNoteReducer from './customerNoteReducer';
import providerReducer from './providerReducer';
import orderReducer from './orderReducer';
import productsReducer from './productReducer';
import proposalReducer from './proposalReducer';
import reportReducer from './reportReducer';
import categoryReducer from './categoryReducer';
import postReducer from './postReducer';
// import notificationsReducer from './notificationsReducer';
import notificationsReducer from './notificationsReducer';
// import chatReducer from './chatReducer';
// import mailReducer from './mailReducer';
// import kanbanReducer from './kanbanReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  customers: customerReducer,
  customerNotes: customerNoteReducer,
  providers: providerReducer,
  orders: orderReducer,
  products: productsReducer,
  proposals: proposalReducer,
  report: reportReducer,
  categories: categoryReducer,
  posts: postReducer,
  // notifications: notificationsReducer,
  notifications: notificationsReducer,
  //chat: chatReducer,
  //mail: mailReducer,
  //kanban: kanbanReducer,
  //form: formReducer
});

export default rootReducer;
