// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = '2.0.0';
export const API_BASE_URL = '/api';
export const ENABLE_REDUX_LOGGER = false;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const STRIPE_MONTHLY_BASIC_PLAN_ID = "price_1IWy5IA5YJgWj2DQ0N7Q6WSY";
export const STRIPE_YEARLY_BASIC_PLAN_ID = "price_1IWy7QA5YJgWj2DQIWbYo3bA";
export const CIRCULO_CUADRO_FEE_PERCENT = 0.01;
export const CIRCULO_CUADRO_FEE_FIXED = 1;
export const CIRCULO_CUADRO_TAX_FEE_PERCENT = 0.16;
export const STRIPE_TRANSACTION_FEE_PERCENT = 0.036;
export const STRIPE_INVOICE_FEE_PERCENT = 0.005;
export const STRIPE_TAX_FEE_PERCENT = 0.16;
export const STRIPE_TRANSACTION_FEE_FIXED = 3;

export default {};
