import axios from 'src/utils/axiosMain';
//import axios from 'axios';
// import authService from 'src/services/authService';

export const CREATE_CUSTOMER = '@customer/create-customer';
export const CREATE_CUSTOMER_FAILURE = '@customer/create-customer-failure';
export const GET_ALL_CUSTOMERS = '@customer/get-all-customers';
export const UPDATE_CUSTOMER = '@customer/update-customer';
export const GET_CUSTOMER = '@customer/get-customer';
export const GET_CUSTOMER_IMPORTS = '@customer/get-customer-imports';
export const DELETE_CUSTOMERS_LIST = '@customer/delete-customers-list';


export function createCustomer(values) {
  return async (dispatch) => {  
    try {
      const response = await axios.post('/api/customers', { ...values });
      dispatch({
        type: CREATE_CUSTOMER,
        payload: response.data
      });
    } catch (error) {
      //console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_FAILURE });
      throw error;
    }
  };
}

export function updateCustomer(values, custId) {
  return async dispatch => {
    try {
        const response = await axios.patch(`/api/customers/${custId}`, { ...values });
        dispatch({ type: UPDATE_CUSTOMER, payload: response.data });
    } catch (error) {
        //dispatch({ type: UPDATE_CUSTOMER, payload: e.response.data });
        throw error;
    }
  }
}

export function getCustomer(custId) { 
  return async dispatch => {
    try {
        const response = await axios.get(`/api/customers/${custId}`);
        dispatch({ type: GET_CUSTOMER, payload: response.data });
    } catch (error) {
        throw error;
        //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  }
}

export function getAllCustomers() {
  return async dispatch => {
    try {
        const response = await axios.get('/api/customers?sortBy=createdAt:desc');
        dispatch({ type: GET_ALL_CUSTOMERS, payload: response.data });   
    } catch (e) {
        dispatch({ type: GET_ALL_CUSTOMERS, payload: e.response.data });
    }
  }
}

export function importCustomer(customerEmail) { 
  return async dispatch => {
    try {
        const response = await axios.post('/api/customers/import-customer', { customerEmail });
        dispatch({ type: GET_CUSTOMER, payload: response.data });
    } catch (error) {
        throw error;
        //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  }
}

export async function importCustomerList(files) { 
  let fd = new FormData();
    fd.append('customersFile', files);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    try {
      const response = await axios.post('/api/customers/import-list', fd, config);
      return response.data
      // dispatch({
      //   type: UPLOAD_USER_AVATAR,
      //   payload: {
      //     user: response.data
      //   }
      // });
    } catch (error) {
      //dispatch({ type: UPLOAD_USER_AVATAR });
      throw error;
    }
}

export function getimportCustomerList() { 
  return async dispatch => {
    try {
        const response = await axios.get('/api/customers/imports-list');
        dispatch({ type: GET_CUSTOMER_IMPORTS, payload: response.data });   
    } catch (e) {
        dispatch({ type: GET_CUSTOMER_IMPORTS, payload: e.response.data });
    }
  }
}

export function deleteCustomerList(customersIds) { 
  return async dispatch => {
    try {
        const response = await axios.delete('/api/customers/delete-list', { data: {customersIds} });
        dispatch({ type: DELETE_CUSTOMERS_LIST, payload: response.data });
    } catch (error) {
        dispatch({ type: DELETE_CUSTOMERS_LIST, payload: error.response.data });
    }
  }
}
