import axios from 'src/utils/axiosMain';
//import axios from 'axios';
import authService from 'src/services/authService';

export const REGISTER_SUCCESS = '@account/register-success';
export const REGISTER_FAILURE = '@account/register-failure';
export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const GET_PROFILE = '@account/get-profile';
export const PROFILE_UPDATE_FAILURE = '@account/profile-update-failure';
export const UPLOAD_USER_AVATAR = '@account/upload-user-avatar';

export function register(formValues) {
  return async dispatch => {
    try {
      //dispatch({ type: LOGIN_REQUEST });

      const user = await authService.register(formValues);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: REGISTER_FAILURE });
      throw error;
    }
  };
}

export function login(email, password, loginType = 'admin') {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithEmailAndPassword(
        email,
        password,
        loginType
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return dispatch =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user
      }
    });
}

export function logout() {
  return async dispatch => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

// export function register() {
//   return true;
// }

// export function updateProfile(update) {
//   const request = await axios.post('/api/users/me', { update });

//   return (dispatch) => {
//     request.then((response) => dispatch({
//       type: UPDATE_PROFILE,
//       payload: response.data
//     }));
//   };
// }

export function updateProfile(values, userRole) {
  return async dispatch => {
    let apiUrl = '/api/users/me';
    if (userRole === 'customer') apiUrl = '/api/customers/me';
    try {
      const response = await axios.patch(apiUrl, values);
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          user: response.data
        }
      });
    } catch (error) {
      dispatch({ type: PROFILE_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function getProfile(userRole = '') {
  return async dispatch => {
    let apiUrl = '/api/users/me';
    if (userRole === 'customer') apiUrl = '/api/customers/me';

    try {
      const response = await axios.get(apiUrl);
      dispatch({
        type: GET_PROFILE,
        payload: {
          user: response.data
        }
      });
    } catch (error) {
      throw error;
      //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  };
}

export function uploadUserAvatar(file) {
  return async dispatch => {
    let fd = new FormData();
    fd.append('avatar', file);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    try {
      const response = await axios.post('/api/users/me/avatar', fd, config);
      dispatch({
        type: UPLOAD_USER_AVATAR,
        payload: {
          user: response.data
        }
      });
    } catch (error) {
      //dispatch({ type: UPLOAD_USER_AVATAR });
      throw error;
    }
  };
}

export function uploadUserCompanyLogo(file) {
  return async dispatch => {
    let fd = new FormData();
    fd.append('companyLogo', file);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    try {
      const response = await axios.post(
        '/api/users/me/company-logo',
        fd,
        config
      );
      dispatch({
        type: UPLOAD_USER_AVATAR,
        payload: {
          user: response.data
        }
      });
    } catch (error) {
      //dispatch({ type: UPLOAD_USER_AVATAR });
      throw error;
    }
  };
}
