// https://jsbin.com/capiyoyusa/1/edit?js,console
import _ from 'lodash';
import { GET_ALL_CATEGORIES, ADD_CATEGORY } from 'src/actions/categoryActions';

const INITIAL_STATE = {
  allCategories: {},
  categoriesFetched: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        allCategories: _.mapKeys(action.payload, '_id'),
        categoriesFetched: true
      };

    case ADD_CATEGORY:
      if (action.payload.error)
        return { ...state, error: action.payload.error };
      else {
        let allCategories = { ...state.allCategories };
        allCategories = {
          ...allCategories,
          [action.payload._id]: action.payload
        };
        return { ...state, allCategories };
      }

    default:
      return state;
  }
};
