// https://jsbin.com/capiyoyusa/1/edit?js,console
import _ from 'lodash';
import {
  CREATE_CUSTOMER_NOTE,
  GET_CUSTOMER_NOTES,
  UPDATE_CUSTOMER_NOTE,
  DELETE_CUSTOMER_NOTE
} from 'src/actions/customerNoteActions';

const INITIAL_STATE = {
  customerNotes: {},
  customerNotesFetched: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_NOTES:
      // action.payload.forEach(customer => {
      //     customer.orders = _.mapKeys(customer.orders, '_id');
      // });

      return {
        ...state,
        customerNotes: _.mapKeys(action.payload, '_id'),
        customerNotesFetched: true
      };

    case CREATE_CUSTOMER_NOTE:
      if (action.payload.error)
        return { ...state, error: action.payload.error };
      else {
        let customerNotes = { ...state.customerNotes };
        customerNotes = {
          ...customerNotes,
          [action.payload._id]: action.payload
        };
        return { ...state, customerNotes };
      }

    case UPDATE_CUSTOMER_NOTE:
      if (action.payload.error)
        return { ...state, error: action.payload.error };
      else {
        let customerNotes = { ...state.customerNotes };
        customerNotes = {
          ...customerNotes,
          [action.payload._id]: action.payload
        };
        return { ...state, customerNotes };
      }

    case DELETE_CUSTOMER_NOTE:
      const customerNoteId = action.payload._id;
      return _.omit(state, [`customerNotes.${customerNoteId}`]);

    default:
      return state;
  }
};
