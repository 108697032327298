import React from 'react';
import { Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'src/components/Auth';
// import CookiesNotification from 'src/components/CookiesNotification';
// import SettingsNotification from 'src/components/SettingsNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
import CustomerDashboardRoutes from 'src/CustomerDashboardRoutes';
import SuperAdminDashboardRoutes from 'src/SuperAdminDashboardRoutes';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

function App() {
  useStyles();

  function getDashboardRoutes(user) {
    switch (user.role) {
      case 'superadmin':
        return <SuperAdminDashboardRoutes />;
      case 'customer':
        return <CustomerDashboardRoutes />;
      default:
        return <Routes />;
    }
  }

  const { settings } = useSettings();
  const user = useSelector(state => state.account.user);

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <ScrollReset />
                <GoogleAnalytics />
                {/* <CookiesNotification />
                <SettingsNotification /> */}
                {user ? getDashboardRoutes(user) : <Routes />}
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
