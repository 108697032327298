// https://jsbin.com/capiyoyusa/1/edit?js,console
import _ from 'lodash';
import { GET_ALL_POSTS, GET_POST } from 'src/actions/postActions';

const INITIAL_STATE = {
  allPosts: {},
  postsFetched: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_POSTS:
      return {
        ...state,
        allPosts: _.mapKeys(action.payload, '_id'),
        postsFetched: true
      };
    case GET_POST:
      let allPosts = { ...state.allPosts };
      allPosts = { ...allPosts, [action.payload._id]: action.payload };
      return { ...state, allPosts };
    default:
      return state;
  }
};
