import axios from 'src/utils/axiosMain';

export const GET_NOTIFICATIONS = '@notifications/get-notifications';

export const MARK_NOTIFICATIONS_AS_READ = '@notifications/mark-notification-as-read';

export function getNotifications() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/notifications');
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: response.data
      });
    } catch (error) {
      console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_FAILURE });
      throw error;
    }
  };
}

export const markNotificationAsRead = (notificationsIds) => async (dispatch) => {
  try {
    await axios.put(`/api/notifications/read`, { notificationsIds });
    dispatch({
      type: MARK_NOTIFICATIONS_AS_READ,
      payload: {notificationsIds}
    });
  } catch (error) {
    console.error('Failed to mark notification as read:', error);
  }
};
