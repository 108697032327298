// https://jsbin.com/capiyoyusa/1/edit?js,console
import _ from 'lodash';
import {
    CREATE_PROVIDER,
    GET_ALL_PROVIDERS,
    UPDATE_PROVIDER,
    GET_PROVIDER
} from 'src/actions/providerActions';

import {
    CREATE_PRODUCT,
    UPLOAD_PRODUCT_IMAGE,
    GET_PRODUCT,
    UPDATE_PRODUCT
} from 'src/actions/productActions';

import {
    CREATE_SERVICE,
    GET_SERVICE,
    UPDATE_SERVICE
} from 'src/actions/serviceActions';

const INITIAL_STATE = {
    allProviders: {},
    providersFetched: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_PROVIDERS:
            action.payload.forEach(provider => {
                provider.products = _.mapKeys(provider.products, '_id');
                provider.services = _.mapKeys(provider.services, '_id');
            });

            return {...state, allProviders: _.mapKeys(action.payload, '_id'), providersFetched: true};
        
        case CREATE_PROVIDER:
            if (action.payload.error) return {...state, error: action.payload.error};
            else {
                let allProviders = { ...state.allProviders };
                action.payload.products = _.mapKeys(action.payload.products, '_id');
                action.payload.services = _.mapKeys(action.payload.services, '_id');
                allProviders = { ...allProviders, [action.payload._id]: action.payload };
                return { ...state, allProviders };
            }

        case UPDATE_PROVIDER:
            if (action.payload.error) return {...state, error: action.payload.error};
            else {
                let allProviders = { ...state.allProviders };
                action.payload.products = _.mapKeys(action.payload.products, '_id');
                action.payload.services = _.mapKeys(action.payload.services, '_id');
                allProviders = { ...allProviders, [action.payload._id]: action.payload };
                return { ...state, allProviders };
            }

        case GET_PROVIDER:
            let allProviders = { ...state.allProviders };
            action.payload.products = _.mapKeys(action.payload.products, '_id');
            action.payload.services = _.mapKeys(action.payload.services, '_id');
            allProviders = { ...allProviders, [action.payload._id]: action.payload };
            return { ...state, allProviders };

        case CREATE_PRODUCT:
        case GET_PRODUCT:
        case UPDATE_PRODUCT:
            if (action.payload.error) return {...state, error: action.payload.error};
            else {
                const products = { ...state.allProviders[action.payload.providerId].products, [action.payload._id]: action.payload };
                let provider  = { ...state.allProviders[action.payload.providerId] };
                let allProviders = { ...state.allProviders };
                
                provider.products = products;
                allProviders[action.payload.providerId] = provider;

                return { ...state, allProviders };
            }

        case UPLOAD_PRODUCT_IMAGE:
            return state;

        case CREATE_SERVICE:
        case GET_SERVICE:
        case UPDATE_SERVICE:
            if (action.payload.error) return {...state, error: action.payload.error};
            else {
                const services = { ...state.allProviders[action.payload.providerId].services, [action.payload._id]: action.payload };
                let provider  = { ...state.allProviders[action.payload.providerId] };
                let allProviders = { ...state.allProviders };
                
                provider.services = services;
                allProviders[action.payload.providerId] = provider;

                return { ...state, allProviders };
            }
        
        default:
            return state;
    }
}