import {
  PieChart as PieChartIcon,
  FileText as FileTextIcon,
} from 'react-feather';

export default [
  {
    subheader: 'Inicio',
    items: [
      {
        title: 'Bienvenido',
        icon: PieChartIcon,
        href: '/app/dashboard'
      }
    ]
  },
  {
    subheader: 'Administración',
    items: [
      {
        title: 'Blog',
        icon: FileTextIcon,
        href: '#',
        id: 'blog-nav',
        items: [
          {
            title: 'Todas las entradas',
            href: '/app/management/posts'
          },
          {
            title: 'Nueva entrada',
            href: '/app/management/posts/create'
          },
          {
            title: 'Categorías',
            href: '/app/management/categories'
          }
        ]
      }
    ]
  }
];
