import React, {
  useRef,
  useState,
  useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon,
  CheckSquare as CheckSquareIcon,
  DollarSign as DollarSignIcon
} from 'react-feather';
import moment from 'moment';
import 'moment/locale/es';
import { getNotifications, markNotificationAsRead } from 'src/actions/notificationsActions';

moment.locale('es');

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon,
  proposal_signed: CheckSquareIcon,
  order_payment_received: DollarSignIcon,
};

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  listItemUnreed: {
    backgroundColor: 'rgba(0, 161, 102, 0.2)'
  }
}));

function Notifications() {
  const classes = useStyles();
  const notificationsState = useSelector((state) => state.notifications.notifications);
  const pendingNotifications = useSelector((state) => state.notifications.pendingNotifications);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [notifications, setNotifications] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAllMarkAsRead = () => {
    const notificationsIds = [...notifications.filter((notification) => !notification.read).map((notification) => notification._id)]
    handleMarkAsRead(notificationsIds);
  }

  const handleMarkAsRead = (notificationsId) => {
    dispatch(markNotificationAsRead(notificationsId));
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  useEffect(() => {
    setUnreadNotifications(pendingNotifications ?? 0);
    setNotifications(notificationsState ?? []);
  }, [pendingNotifications, notificationsState]);
  
  return (
    <>
      <Tooltip title="Notificaciones">
        <IconButton
          color="inherit"
          ref={ref}
          onClick={handleOpen}
        >
          {unreadNotifications > 0 && (
            <Badge badgeContent={unreadNotifications} color="error">
              <SvgIcon>
                <BellIcon />
              </SvgIcon>
            </Badge>
          )}
          {unreadNotifications === 0 && (
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          )}
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Notificaciones
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              No tienes notificaciones
            </Typography>
          </Box>
        ) : (
          <>
            <List
              className={classes.list}
              disablePadding
            >
              {notifications.map((notification) => {
                const Icon = iconsMap[notification.type];

                return (
                  <ListItem
                    className={(!notification.read ? classes.listItemUnreed : '')}
                    component={RouterLink}
                    divider
                    key={notification._id}
                    to={notification.redirect}
                    onClick={() => handleMarkAsRead([notification._id])}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={
                        <React.Fragment>
                          <Typography
                            style={{ display: 'block' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {notification.description}
                          </Typography>
                          <Typography
                            style={{ display: 'block' }}
                            component="span"
                            variant="caption"
                            color="text.secondary"
                          >
                            {moment(notification.createdAt).format('LLL')}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box
              p={1}
              display="flex"
              justifyContent="center"
            >
              <Button
                component={RouterLink}
                size="small"
                to="#"
                onClick={handleAllMarkAsRead}
              >
                Marcar todas como leídas
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
}

export default Notifications;
