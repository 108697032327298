import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { useDispatch, useSelector } from 'react-redux';

import { getNotifications } from 'src/actions/notificationsActions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { user } = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.role !== 'admin') return;
    const eventSource = new EventSource('/api/customers/events');

    eventSource.onopen = (event) => {
      // console.log('Conexión abierta');
    };

    eventSource.addEventListener('import', (event) => {
      // const message = JSON.parse(event.data);
      enqueueSnackbar('Importación finalizada', {
        autoHideDuration: 5000,
        variant: 'success'
      });
    });
    
    eventSource.addEventListener('notification', (event) => {
      console.log('Tipo:',  typeof event.data);
      const message = JSON.parse(event.data);
      console.log('Notificación:', message.title);
      enqueueSnackbar('Cotización firmada', {
        autoHideDuration: 5000,
        variant: 'success'
      });
      dispatch(getNotifications());
    });

    eventSource.onerror = (error) => {
      console.error('Error en la conexión:', error);
    };

    return () => {
      eventSource.close();
    };
  }, [enqueueSnackbar, user.role]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
