/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_NOTIFICATIONS, MARK_NOTIFICATIONS_AS_READ } from 'src/actions/notificationsActions';

const initialState = {
  notifications: [],
  pendingNotifications: 0
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      const { notifications, pendingNotifications } = action.payload;

      return produce(state, (draft) => {
        draft.notifications = notifications;
        draft.pendingNotifications = pendingNotifications;
      });
    }

    case MARK_NOTIFICATIONS_AS_READ: {
      const { notificationsIds } = action.payload;

      return produce(state, (draft) => {
        draft.notifications = draft.notifications.map((notification) => {
          if (notificationsIds.includes(notification._id)) {
            notification.read = true;
          }
          return notification;
        });

        draft.pendingNotifications -= notificationsIds.length;
      });
    }

    default: {
      return state;
    }
  }
};

export default notificationsReducer;
