import axios from 'src/utils/axiosMain';
//import axios from 'axios';
// import authService from 'src/services/authService';

export const CREATE_ORDER = '@order/create-order';
export const CREATE_ORDER_FAILURE = '@order/create-order-failure';
export const GET_ALL_ORDERS = '@order/get-all-orders';
export const UPDATE_ORDER = '@order/update-order';
export const GET_ORDER = '@order/get-order';
export const DELETE_MANY_ORDER = '@order/get-many-order';

export function createOrder(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/orders', { ...values });
      dispatch({
        type: CREATE_ORDER,
        payload: response.data
      });
    } catch (error) {
      //console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_FAILURE });
      throw error;
    }
  };
}

export function getAllOrders(userRole) {
  return async dispatch => {
    try {
      let apiUrl = '/api/orders?sortBy=createdAt:desc&limit=100';
      if (userRole === 'customer')
        apiUrl = '/api/customers/my-orders?sortBy=createdAt:desc&limit=100';
      const response = await axios.get(apiUrl);
      dispatch({ type: GET_ALL_ORDERS, payload: response.data });
    } catch (e) {
      dispatch({ type: GET_ALL_ORDERS, payload: e.response.data });
    }
  };
}

export function getOrder(values, userRole) {
  return async dispatch => {
    try {
      let apiUrl = `/api/orders/${values.orderId}?customerId=${values.customerId}`;
      if (userRole === 'customer')
        apiUrl = `/api/customers/my-order/${values.orderId}`;
      const response = await axios.get(apiUrl);
      dispatch({ type: GET_ORDER, payload: response.data });
    } catch (error) {
      throw error;
      //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  };
}

export function updateOrder(values) {
  return async dispatch => {
    try {
      const response = await axios.patch(`/api/orders/${values.orderId}`, {
        ...values
      });
      dispatch({
        type: UPDATE_ORDER,
        payload: response.data
      });
    } catch (error) {
      //console.log(error);
      //dispatch({ type: CREATE_CUSTOMER_FAILURE });
      throw error;
    }
  };
}

export function deleteManyOrders(orderIds, userRole) {
  return async dispatch => {
    try {
      let apiUrl = `/api/orders/delete`;
      // if (userRole === 'customer') apiUrl = `/api/customers/my-order/`;
      const response = await axios.delete(apiUrl, {
        data: {
          orderIds
        }
      });
      dispatch({ type: DELETE_MANY_ORDER, payload: response.data });
    } catch (error) {
      throw error;
      //dispatch({ type: GET_CUSTOMER, payload: e.response.data });
    }
  };
}
